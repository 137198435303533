.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active {
  color: green;
}

.number-badge {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -3px;
  top: -13px;
  font-size: 12px;
}

#file-upload-button {
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 1px;
  align-items: center;
  display: inline-flex;
  margin-right: 1rem;
  -webkit-appearance: button;
}

.message-bubble {
  width: fit-content !important;
  max-width: 75%;
}

.message-area {
  max-height: 40vh;
}

#sendIt {
  transform:rotate(45deg)
}

.rotate-45 {
  transform: rotate(45deg);
}

.w-fit {
  width: fit-content;
}

.chat-box {
  max-width: 30rem;
  transform: translateX(-40%);
} 

/* --- SHOPIFY LOADER --- */

.body-preloader
{
    align-items: center;
    height: 100vh;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    transition: .5s; 
    opacity: 1;
    background-color: #2a2e3c;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.foxic-body-loader
{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 120px;
    height: 120px;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.foxic-body-loader .preloader-circle-1
{
    width: 120px;
    height: 120px;
    animation: clockwise .625s linear infinite; 
    opacity: .75;
}
.foxic-body-loader .preloader-circle-2
{
    position: relative;
    position: absolute;
    top: 0;
    left: 0; 
    overflow: hidden;
    width: 120px;
    height: 120px;
}
.foxic-body-loader .preloader-circle-3
{
    position: absolute;
    position: absolute;
    top: 0;
    top: 0;
    left: 0;
    left: 0;
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    margin: 0;
    animation: loader-rotate 1.25s cubic-bezier(.4, 0, .22, 1) infinite; 
    border: 10px solid #585c68;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    background: none;
}
.foxic-body-loader .preloader-circle-3:after,
.foxic-body-loader .preloader-circle-3:before
{
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    border-radius: 50%;
    background-color: #585c68;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, .3);
}
.foxic-body-loader .preloader-circle-3:after
{
    top: 5%;
    left: 7%;
}
.foxic-body-loader .preloader-circle-3:before
{
    right: 7%; 
    bottom: 5%;
}
.foxic-body-loader svg
{
    position: absolute;
    min-width: 60%;
    min-height: 60%;
    transform: scale(.35);
    animation-name: pulse;
    animation-duration: 1.25s;
    animation-timing-function: ease-in; 
    animation-iteration-count: infinite;
    opacity: .5;
    fill: #585c68;
}
.foxic-body-loader .body-loader-image
{
    width: 60px;
    position: absolute;
    max-width: 100%;
    transform: scale(.5);
    animation-name: pulseImage;
    animation-duration: 1.25s;
    animation-timing-function: ease-in; 
    animation-iteration-count: infinite;
    opacity: .75;
}
.foxic-loader
{
    position: absolute;
    z-index: 1;
    width: 36px;
    height: 36px;
    animation: 1s spinLoader linear infinite; 
    border: 2px solid #fff;
    border-top-color: #647482;
    border-radius: 50%;
    background-color: transparent;
}
@keyframes pulse
{
    from
    {
        transform: scale(.4);
        opacity: .5;
    }
    50%
    {
        transform: scale(.65);
        opacity: 1;
    }
    to
    {
        transform: scale(.4);
        opacity: .5;
    }
}
@keyframes pulseImage
{
    from
    {
        transform: scale(.5);
        opacity: .5;
    }
    50%
    {
        transform: scale(.95);
        opacity: 1;
    }
    to
    {
        transform: scale(.5);
        opacity: .5;
    }
}
@keyframes clockwise
{
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}
@keyframes loader-rotate
{
    0%
    {
        transform: rotate(0);
    }
    50%
    {
        transform: rotate(-140deg);
    }
    100%
    {
        transform: rotate(0);
    }
}
@keyframes spinLoader
{
    from
    {
        transform: rotate(0deg);
    }
    to
    {
        transform: rotate(360deg);
    }
}